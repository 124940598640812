import axios from "axios";
import { ElMessage } from "element-plus";


// https://pay.91ronghuitong.com/api

const instance = axios.create({
    // baseURL: "http://127.0.0.1:3000/task",
    baseURL: "https://pay.baiyihuoban.com/api/website/",
    timeout: "30000",
    headers: {
        "Content-Type": "application/json"
    }
})

instance.interceptors.response.use((response) => {

    if (response.status == 200) {
        if (response.data.code == 200) {
            return response.data
        }
    }
    else {
        ElMessage.error("11111")
    }

}, (error) => {
    let { message } = error;
    ElMessage.error(message)
    return Promise.reject(error);
})

export default instance; 